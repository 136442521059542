import * as React from "react";
import { Link } from "gatsby";

// styles
const pageStyles = {
  backgroundColor: "white",
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
};

const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
export default function IndexMenu() {
  return (
    <main style={pageStyles} className="grid place-items-center">
      <title>Not found</title>
      <div className="flex mb-4">
        <div className="text-5xl">4</div>
        <i className="far fa-question-circle fa-spin"></i>
        <div className="text-5xl">4</div>
      </div>

      <p>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        we couldn’t find what you were looking for.
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">Go home</Link>.
      </p>
    </main>
  );
}
